<template>
  <div
    class="item-form-wrapper"
  >
    <b-sidebar
      v-model="state"
      :width="`${width}px`"
      :no-header-close="true"
      :backdrop="true"
      right
      shadow
      @hidden="onCancel"
    >
      <b-alert v-if="isLocked" variant="warning" show>
        {{ $t('eLt_locked', { email: isLockedBy.email, id: isLockedBy.by }) }}
      </b-alert>
      <errors :items="formErrors" />
      <div class="px-3 py-2">
        <slot>
          <b-row>
            <b-col
              v-for="field in fields"
              :key="field.key"
              :class="(field.class ? field.class : '') + (isHiddenField(field) ? ' d-none' : '')"
            >
              <b-form-group
                :id="field.key"
                :label="!isHiddenField(field) ? field.label : ''"
                :label-for="`form-input-${field.key}`"
              >
                <p
                  v-if="isPreviewField(field)"
                  v-shtml="typeof field.formatter === 'function'
                    ? field.formatter(rawData[field.key], field.key, rawData)
                    : rawData[field.key]"
                  class="sm"
                />
                <b-form-select
                  v-else-if="isSelectField(field)"
                  :id="`form-input-${field.key}`"
                  v-model="rawData[field.key]"
                  :options="formData[field.key]"
                  label-field="value"
                  value-field="id"
                  size="sm"
                  :disabled="isLocked"
                />
                <b-form-textarea
                  v-else-if="isTextAreaField(field)"
                  :id="`form-input-${field.key}`"
                  v-model="rawData[field.key]"
                  :disabled="isLocked"
                />
                <client-only
                  v-else-if="isHtmlEditorField(field)"
                >
                  <quill-editor
                    :id="`form-input-${field.key}`"
                    v-model="rawData[field.key]"
                    :disabled="isLocked"
                  />
                </client-only>
                <b-form-datepicker
                  v-else-if="isDateField(field)"
                  :id="`fast-edit-input-${field.key}`"
                  v-model="rawData[field.key]"
                  :options="formData[field.key]"
                  size="sm"
                  :disabled="isLocked"
                />
                <b-form-input
                  v-else-if="isNumberField(field)"
                  :id="`fast-edit-input-${field.key}`"
                  v-model="rawData[field.key]"
                  type="number"
                  size="sm"
                  :disabled="isLocked"
                />
                <input
                  v-else-if="isHiddenField(field)"
                  :id="`fast-edit-input-${field.key}`"
                  v-model="rawData[field.key]"
                  type="hidden"
                >
                <b-form-input
                  v-else
                  :id="`form-input-${field.key}`"
                  v-model="rawData[field.key]"
                  trim
                  size="sm"
                  :disabled="isLocked"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </slot>
        <slot name="details" />
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          {{ parentTitle }}
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn
            variant="primary"
            size="sm"
            :disabled="isLocked"
            @click="onSubmitClose"
          >
            {{ $t('eDoc_form_a_submitClose') }}
          </b-btn>
          <b-btn
            variant="primary"
            size="sm"
            class="ml-1"
            :disabled="isLocked"
            @click="onSubmit"
          >
            {{ $t('eDoc_form_a_submit') }}
          </b-btn>
          <b-btn
            variant="secondary"
            size="sm"
            class="ml-1"
            @click="onCancel"
          >
            {{ $t('eDoc_form_a_cancel') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'ItemForm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => ([])
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      state: this.value,
      isFetched: false,
      rawData: {}
    }
  },
  async fetch () {
    if (this.state) {
      if (this.itemId !== null && parseInt(this.itemId) > 0) {
        await this.call({
          repo: this.repo,
          method: 'edit',
          id: this.itemId
        })
        await this.call({
          repo: this.repo,
          method: 'show',
          id: this.itemId
        })
        this.isFetched = true
      } else {
        await this.call({
          repo: this.repo,
          method: 'create'
        })
      }
    }
    this.$nextTick(() => {
      this.getData()
    })
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      errors: 'repos/errors',
      isLockedRepo: 'shared/isLocked',
      isLockedByRepo: 'shared/isLockedBy',
      deviceInfo: 'device',
      pageTitle: 'layout/pageTitle'
    }),
    formData () {
      if (this.itemId !== null && parseInt(this.itemId) > 0) {
        return this.getByName(`${this.repo}/edit`) || []
      } else {
        return this.getByName(`${this.repo}/create`) || []
      }
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isTablet () {
      return this.deviceInfo?.type === 'tablet' || false
    },
    width () {
      if (isServer) {
        return 0
      } else if (this.isMobile) {
        return document.body.offsetWidth
      } else if (this.isTablet) {
        return Math.round(document.body.offsetWidth * 0.90)
      }
      return Math.round(document.body.offsetWidth * 0.80)
    },
    displayTitle () {
      let title
      if (this.title) {
        title = this.title
      } else {
        title = `${
          this.itemId !== null
            ? this.$t('eDoc_form_labelEdit')
            : this.$t('eDoc_form_labelNew')
        } ${this.$t('eDoc_form_labelItem')} ${this.itemId !== null ? ` - ID:${this.itemId}` : ''}`
      }
      return title
    },
    parentTitle () {
      return this.pageTitle ? this.$t(this.pageTitle) : ''
    },
    isLocked () {
      return this.isLockedRepo(this.repo, this.itemId)
    },
    isLockedBy () {
      return this.isLockedByRepo(this.repo, this.itemId)
    },
    formErrors () {
      return this.errors.filter((error) => {
        return [
          'show',
          'create',
          'edit',
          'store',
          'update'
        ].includes(error.method)
      })
    }
  },
  watch: {
    // TODO: replace as GlobalForm
    itemId (n, o) {
      if (!this.isLocked) {
        if (n) {
          this.$repo.lockItem(this.repo, n)
        } else if (o && !this.state) {
          this.$repo.unlockItem(this.repo, o)
        }
        // load record on after create
        if (!this.isFetched && !o && n) {
          this.$nextTick(() => {
            this.$fetch()
          })
        }
      }
    },
    async value (n) {
      this.state = !!n
      if (this.state) {
        await this.$fetch()
        this.getData()
        this.$forceUpdate()
      } else {
        this.flush({
          repo: this.repo,
          method: 'create',
          id: this.itemId
        })
        this.flush({
          repo: this.repo,
          method: 'edit',
          id: this.itemId
        })
        this.flush({
          repo: this.repo,
          method: 'show',
          id: this.itemId
        })
        this.rawData = {}
      }
    },
    formData: {
      handler (v, o) {
        let canRefresh = false
        if (
          typeof v !== 'undefined'
        ) {
          if (typeof o !== 'undefined') {
            const vJson = JSON.stringify(v)
            const oJson = JSON.stringify(o)
            if (vJson !== oJson) {
              canRefresh = true
            }
          } else {
            canRefresh = true
          }
        }
        if (canRefresh && this.state) {
          this.$nextTick(() => {
            this.getData()
          })
        }
      },
      deep: true
    }
  },
  beforeDestroy () {
    // TODO: replace as GlobalForm
    if (!this.isLocked) {
      this.$repo.unlockItem(this.repo, this.itemId)
    }
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    getData () {
      if (this.itemId !== null && parseInt(this.itemId) > 0) {
        const rawData = this.getByName(`${this.repo}/show`)
        this.rawData = rawData && rawData.data ? Object.assign(this.rawData, rawData.data) : {}
      } else {
        this.fields.forEach((f) => {
          if (typeof this.rawData[f.key] === 'undefined') {
            if (typeof f.default !== 'undefined') {
              this.rawData[f.key] = f.default
            } else if (f.type !== 'preview') {
              let value
              switch (f.type) {
                case 'select':
                  value = ''
                  if (this.formData[f.key]?.length > 0) {
                    value = this.formData[f.key][0].id
                  }
                  break
                case 'input':
                case 'textarea':
                case 'editor':
                case 'date':
                case 'number':
                case 'hidden':
                default:
                  value = ''
                  break
              }
              this.rawData[f.key] = value
            }
          }
        })
      }
    },
    isSelectField (field) {
      return field && field.type === 'select'
    },
    isTextAreaField (field) {
      return field && field.type === 'textarea'
    },
    isHtmlEditorField (field) {
      return field && field.type === 'editor'
    },
    isPreviewField (field) {
      return field && field.type === 'preview'
    },
    isDateField (field) {
      return field &&
        field.type === 'date'
    },
    isNumberField (field) {
      return field &&
        field.type === 'number'
    },
    isHiddenField (field) {
      return field && field.type === 'hidden'
    },
    onCancel () {
      this.flushError()
      this.state = false
      this.$emit('input', this.state)
      this.$emit('on-cancel')
    },
    async onSubmit () {
      this.flushError()
      if (this.rawData && this.rawData.id) {
        await this.call({
          repo: this.repo,
          method: 'update',
          id: this.rawData.id,
          payload: this.rawData
        })
      } else {
        const response = await this.call({
          repo: this.repo,
          method: 'store',
          payload: this.rawData
        })
        this.$emit('on-create', response && response.id)
      }
      this.$nextTick(() => {
        if (!this.errors || this.errors.length === 0) {
          this.$fetch()
        }
      })
    },
    async onSubmitClose () {
      this.flushError()
      if (this.rawData && this.rawData.id) {
        await this.call({
          repo: this.repo,
          method: 'update',
          id: this.rawData.id,
          payload: this.rawData
        })
      } else {
        await this.call({
          repo: this.repo,
          method: 'store',
          payload: this.rawData
        })
      }
      this.$nextTick(() => {
        if (!this.errors || this.errors.length === 0) {
          this.state = false
          this.$emit('input', this.state)
          this.$emit('on-submit', this.rawData)
        }
      })
    }
  }
}
</script>

<style>
  .item-form-wrapper .item-form-inner-overlay {
    background: rgba(0,0,0,0.5);
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1031;
  }
  .ql-toolbar.ql-snow {
    overflow: hidden;
    overflow-x: auto;
  }
</style>
