<template>
  <div class="global-forms-wrapper">
    <div
      v-if="isFormOpen"
      class="item-form-overlay"
    />
    <component
      :is="chainItem.name"
      v-for="(chainItem, i) in chainItems"
      :key="i"
      :ref="`component_${chainItem.idx}`"
      v-model="chainItem.value"
      :item-id="chainItem.id"
      :repo="chainItem.repo"
      :idx="chainItem.idx"
      @on-cancel="onCancel(chainItem.idx)"
      @on-submit="(e) => { onSubmit(chainItem.idx, e) }"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'GlobalForms',
  computed: {
    ...mapFields({
      chainItems: 'repos.chain'
    }),
    isFormOpen () {
      return this.chainItems
        .filter(item => item.value === true).length > 0
    }
  },
  methods: {
    ...mapActions({
      chainHide: 'repos/chainHide',
      chainDel: 'repos/chainDel'
    }),
    onCancel (idx) {
      // close form
      this.chainHide(idx)
      this.chainDel(idx)
    },
    onSubmit (idx, { close }) {
      // close form
      if (close) {
        this.chainHide(idx)
        this.chainDel(idx)
      }
    }
  }
}
</script>

<style scoped>
.item-form-overlay {
  background: rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
}
</style>
