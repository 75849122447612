<template>
  <div>
    <b-alert
      v-for="(error, i) in items"
      :key="`${i}-${items.length}`"
      :variant="error.variant"
      show
      dismissible
      @dismissed="() => { onFlushError(error) }"
    >
      <font-awesome-icon v-if="error.icon" :icon="error.icon" class="mr-2" size="lg" />
      {{ error.message }}
    </b-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Errors',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    ...mapActions({
      dismissError: 'repos/dismissError'
    }),
    onFlushError (e) {
      this.dismissError(e)
    }
  }
}
</script>

<style scoped>

</style>
