<template>
  <div class="d-flex flex-wrap w-100 px-1 pb-1">
    <b-alert
      :show="isMounted && !isWorkerUnavailable && !isConnected"
      variant="warning"
      dismissible
      style="position: relative; top: 0; z-index: 1100;"
      class="w-100 mb-1"
    >
      {{ $t('eLt_err_pusherUnable') }} <b-link @click="retry">
        [{{ $t('eLt_btnRetry') }}]
      </b-link>
    </b-alert>
    <b-alert
      :show="isMounted && !isWorkerUnavailable && !isOnline"
      variant="warning"
      dismissible
      style="position: relative; top: 0; z-index: 1100;"
      class="w-100 mb-1"
    >
      {{ $t('eLt_err_offline') }} <b-link @click="retry">
        [{{ $t('eLt_btnRetry') }}]
      </b-link>
    </b-alert>
    <b-alert
      :show="isMounted && !isWorkerUnavailable && isWorkerLost && killCounter"
      variant="warning"
      dismissible
      style="position: relative; top: 0; z-index: 1100;"
      class="w-100 mb-1"
      @dismissed="stopTimer"
    >
      {{ $t('eLt_err_outdate', { killCounter }) }} <b-link @click="reload">
        [{{ $t('eLt_btnReload') }}]
      </b-link>
    </b-alert>
    <b-alert
      :show="isMounted && isWorkerUnavailable"
      variant="danger"
      dismissible
      style="position: relative; top: 0; z-index: 1100;"
      class="w-100 mb-1"
    >
      {{ $t('eLt_err_browserVer') }}
    </b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AppState',
  data () {
    return {
      killTimer: null,
      killCounter: null,
      isMounted: false
    }
  },
  computed: {
    ...mapFields({
      portId: 'shared.portId'
    }),
    ...mapGetters({
      isConnected: 'shared/isConnected',
      isOnline: 'shared/isOnline',
      isWorkerUnavailable: 'shared/isWorkerUnavailable',
      isWorkerLost: 'shared/isWorkerLost'
    })
  },
  watch: {
    isWorkerLost (n) {
      if (n && !this.killTimer && this.isMounted && !this.isWorkerUnavailable) {
        this.killCounter = 30
        this.killTimer = setInterval(() => {
          this.killCounter--
          if (this.killCounter === 0) {
            clearInterval(this.killTimer)
            this.killTimer = null
            this.killCounter = null
            location.reload()
          }
        }, 1000)
      }
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      initNav: 'nav/init',
      setActivePath: 'nav/setActivePath',
      killWorker: 'shared/die'
    }),
    retry () {
      // kill
      this.killWorker()
    },
    reload () {
      clearInterval(this.killTimer)
      location.reload()
    },
    stopTimer () {
      clearInterval(this.killTimer)
      this.killTimer = null
      this.killCounter = null
    }
  }
}
</script>

<style scoped>

</style>
